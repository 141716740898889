* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.bprabhu-main {
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: inline-block;
  position: relative;
}

.bprabhu-main img {
  width: 92%;
  height: 500px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}

.Bprabhus-contnt {
  margin-top: 50px;
}

.bprabhu-main-containers {
  display: flex;
  height: auto;
  width: auto;
}

.bprabhu-main-rooms-container {
  width: 157vh;
  height: auto;
}

.bengaluru-counter-cnt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.add-btn,
.increment-btn,
.decrement-btn {
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin: 0 5px;
}

.add-btn {
  border-radius: 20px;
  font-family: "Open Sans";
  font-size: 20px;
  width: 70px;
  box-shadow: none;
  border: 1px solid black;
}

.add-btn:hover {
  background-color: #0e0e0f;
  color: white;
}

.increment-btn {
  color: black;
  font-size: 20px;
  background-color: white;
  border-radius: 70%;
}

.increment-btn:hover {
  width: fit-content;
  height: fit-content;
  background-color: black;
  color: white;
  box-shadow: 1px 1px 0px 0px rgb(193, 194, 198);
}

.decrement-btn {
  background-color: white;
  font-size: 20px;
  color: black;
  border-radius: 70%;
}

.decrement-btn:hover {
  width: fit-content;
  height: fit-content;
  background-color: black;
  color: white;
  box-shadow: 1px 1px 0px 0px gray;
}

.increment-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  color: gray;
}

.count-dis {
  font-size: 18px;
  margin: 0 10px;
  color: gray;
}

.facilities-bng {
  gap: 40px;
  max-height: 50px;
  max-width: 50px;
}

.facilities-bng-text {
  width: 130px;
}
#search-card-grid-bprahu {
  border-radius: 10px;
  width: auto;
  height: auto;
  left: 5%;
  position: absolute;
  bottom: 22%;
}

.carddd {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: fit-content;
  height: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  background-color: rgb(224, 60, 27);
}

.carddd p {
  width: fit-content;
}

.carddd button {
  height: fit-content;
  width: fit-content;
  border: none;
  color: black;
  cursor: pointer;
}

.carddd button:disabled {
  cursor: not-allowed;
}

.counter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

@media (max-width: 600px) {
  .add-button,
  .counter-button {
    padding: 8px 16px;
    font-size: 1.2rem;
  }

  .count-display {
    font-size: 1.5rem;
  }
  .bprabhu-main-containers {
    display: inline-block;
    height: fit-content;
    /* width: fit-content; */
  }
}

.my-selection-card {
  position: sticky;
  top: 0;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
}
.tabs-container {
  min-height: 350px;
  min-width: 100%;
}

.bprabhu-tabs-description {
  min-height: 250px;
}

.facilities {
  min-height: 250px;
}
.tabs-map-container {
  min-height: 250px;
}

.review-tab {
  min-height: 250px;
  overflow-y: auto;
}
.tabs-gallery-container {
  min-height: 250px;
}

.local-tours {
  min-height: 250px;

  overflow-y: auto;
}

.explore-more-btn {
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.tabs-container .nav-tabs {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
}

.tabs-container .nav-item {
  flex: 1;
  text-align: center;
}

.tabs-container .tab-content {
  margin-top: 20px;
}
.facilities {
  margin: 10px 0;
}
.image-wrapper {
  position: relative;
}
.facilities-bng {
  width: 100%;
  height: auto;
}
.hover-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.image-wrapper:hover .hover-text {
  opacity: 1;
}
.facilities-bng-text {
  text-align: start;
  margin-top: 10px;
}
.amenity-col {
  margin-bottom: 20px;
}

@media (max-width: 1000px) {
  .tabs-container .nav-tabs {
    width: auto;
    overflow-x: scroll;
  }

  .tabs-container .nav-item {
    flex: 1;
    min-width: 120px;
    text-align: center;
  }

  .tabs-container .tab-content {
    padding: 0 10px;
  }

  .model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    display: none;
  }
  .bprabhu-main-rooms-container {
    height: auto;
    width: auto;
  }
  .bprabhu-main-containers {
    display: inline-block;
    width: auto;
  }
}
@media (max-width: 480px) {
  .bprabhu-main img {
    width: 84%;
    height: 200px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }
}
