.hostelsignup-main {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px; /* Padding for better appearance */
  position: relative; /* Ensure absolute positioning works for child elements */
}

.hostelsignup-img {
  -webkit-filter: blur(2.5px);
  filter: blur(2.5px);
  width: 100%; 
  height: auto;
}

.overtext-hostelsignup {
  position: absolute;
  top: 45%;
  left: 30%;
  color: white;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 40%; /* Adjusted width for better readability on mobile */
  padding: 20px;
  text-align: start;
  font-family: "Open Sans", sans-serif;
}

/* Overlay heading */
.overfont-hostelsignup {
  width: 100%;
  left: 50%;
  color: whitesmoke;
  text-align: start;
  font-style: normal;
  font-size: 50px; /* Slightly smaller font size for better mobile view */
  font-weight: 900;
  font-family: "Open Sans", sans-serif;
}

/* Button */
.over-btn {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px; /* Adjusted font size for better mobile view */
  font-weight: bold;
  border: none;
  background-color: rgb(149, 2, 75);
}

/* Mobile responsiveness */
@media only screen and (max-width: 900px) {

  .overtext-hostelsignup {
    color: white;
    top: 50%;
    left: 50%;
    font-size: 10px; /* Adjusted font size for readability on mobile */
    transform: translate(-50%, -50%);
    font-weight: 100;
    width: 90%; 
  }

  .overfont-hostelsignup{
    width: 100%;
    font-weight: 100;
    margin-left: 70px;
    font-size: 20px; /* Adjusted font size for readability on mobile */
  }

  .over-btn {
    margin-left: 40px;
    width: 100%; /* Adjusted width for better touch interaction on mobile */
    font-size: 16px; /* Adjusted font size for readability on mobile */
  }
}
