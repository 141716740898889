

.terms-main-container {
  display: flex;
 margin: 50px auto;
  font-family: 'Open Sans',sans-serif;
}
.terms-list-container {
  top: 0;
  border: none;
  width: 100%;
  max-width: 500px;
  box-shadow: none;
  position: sticky;
  padding: 10px;
  font-family: 'Open Sans',sans-serif;
}
.terms-sections-container {
  margin: 0 auto;
  overflow-y: auto;
  height: 80vh;
  width:fit-content;
  font-family: 'Open Sans',sans-serif;

}
.terms-section {
  width: 100%;
  max-width: 400px;
  position: relative;
  margin-bottom: 20px;
  color: rgb(58, 57, 57);
  font-family: 'Open Sans',sans-serif;

}
.terms-list-group-item {
  list-style-type: none;
  padding: 10px;
  cursor: pointer;
  font-family: 'Roboto',sans-serif;
  border-left:5px solid gray; 
}




.terms-list-group-item a {
  text-decoration: none;
  font-family: 'Open Sans',sans-serif;

}

.terms-list-group-item a:hover {
  transform: scale(1.1);
  transition: 1.1s;
  /* color: rgb(96, 5, 232); */
}
@media only screen and (max-width: 900px) {
  .terms-main-container {
    margin: 20px;
  }

  .terms-list-container {
    width: 90%; /* Increase width on medium screens */
    margin: 0 auto; /* Center align on medium screens */
  }

  .terms-sections-container {
    width: 90%; /* Adjust width for medium screens */
  }
}

@media only screen and (max-width: 600px) {
  .terms-main-container {
    margin: 10px;
    flex-direction: column; /* Stack items vertically on small screens */
  }

  .terms-list-container {
    width: 100%;
    margin: 0 auto;
    display: none;

    padding: 5px; /* Reduce padding for smaller screens */
  }

  .terms-sections-container {
    width: 100%;
    margin-top: 20px;
  }

  .terms-section {
    width: 100%; /* Ensure full width for small screens */
    position: relative; /* Use relative positioning */
  }
}