.opcontnt {
  margin-top: "20px";
  padding: "20px";
}
.cprabhu-main-containers {
  display: flex;
  height: auto;
  width: auto;
}
.cprabhu-main-rooms-container {
  height: auto;
  width: 157vh;
}
.cprabhu-contnt {
  margin-top: 50px;
  /* margin-left: 20px; */
}
.cprabhu-main {
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: inline-block;
  position: relative;
}
.cprabhu-main img {
  width: 92%;
  height: 500px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}
.my-selection {
  position: sticky;
  top: 0;
  width: 350px;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
}
.cprabhu-tabs-description {
  height: auto;
  width: auto;
}
.facilities-chennai {
  /* gap: 40px; */
  max-height: 40px;
  max-width: 40px;
}
.facilities {
  width: auto;
  height: auto;
}
.gallery {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;
  -webkit-column-break-after: auto;
}
.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}
.gallery .pics:hover {
  filter: opacity(0.8);
}

.responsive-wrapper {
  margin-left: -10px;
}

@media (max-width: 991px) {
  .gallery {
    -webkit-column-count: 10;
    -moz-column-count: 10;
    column-count: 10;
  }
}
@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 10;
    -moz-column-count: 10;
    column-count: 10;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }

  .cprabhu-main img {
    width: 84%;
    height: 200px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }
  .tabs-container .nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    /* white-space: nowrap; */
    gap: 10px;
  }
}
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  transition: opacity 0.4% ease, visibility 0.4% ease,
    transform 0.5% ease-in-out;
  visibility: visible;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}
.model.open {
  visibility: visible;
  opacity: 2;
  transform: scale(1);
}
.model img {
  width: 50%;
  /* max-width: 100%; */
  height: 55%;
  /* max-height: 100%; */
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
  filter: brightness(5px);
  border-radius: 10px;
}
.model.open svg {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  filter: blur(0.5px);
  border-radius: 20px;
  cursor: pointer;
}
/* src/CounterButton.css */
.counter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
.chennai-add-button,
.increment-button,
.decrement-button {
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin: 0 5px;
}
.chennai-add-button {
  border-radius: 20px;
  font-family: "Open Sans";
  font-size: 16px;
  width: 100px;
  box-shadow: none;
  border: 1px solid black;
}
.chennai-add-button:hover {
  background-color: #0e0e0f;
  color: white;
}
.increment-button {
  background-color: transparent;
  border-radius: 50%;
}
.increment-button:hover {
  width: fit-content;
  height: fit-content;
  box-shadow: 1px 1px 0px 0px gray;
}

.decrement-button {
  background-color: transparent;
  border-radius: 50%;
}
.decrement-button:hover {
  width: fit-content;
  height: fit-content;
  box-shadow: 1px 1px 0px 0px gray;
}
.count-display {
  font-size: 22px;
  margin: 0 10px;
  min-width: 30px;
  text-align: center;
}

.counter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
.add-button,
.increment-button,
.decrement-button {
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin: 0 5px;
}
.add-button {
  border: 2px solid black;
  border-radius: 30px;
}
.add-button:hover {
  box-shadow: 1px 0px 0px 1px rgb(32, 31, 31);
}
.increment-button {
  background-color: transparent;
  border-radius: 50%;
}
.increment-button:hover {
  width: fit-content;
  height: fit-content;
  box-shadow: 1px 1px 0px 0px gray;
}

.decrement-button {
  background-color: transparent;
  border-radius: 50%;
}
.decrement-button:hover {
  width: fit-content;
  height: fit-content;
  box-shadow: 1px 1px 0px 0px gray;
}
.count-display {
  font-size: 22px;
  margin: 0 10px;
  min-width: 30px;
  text-align: center;
}
@media (max-width: 600px) {
  .add-button,
  .counter-button {
    padding: 8px 16px;
    font-size: 1.2rem;
  }

  .count-display {
    font-size: 1.5rem;
  }
  .cprabhu-main-containers {
    display: inline-block;
    /* width: 800px; */
  }
}
.tabs-container {
  margin: 20px;
}

.tabs-container .nav-tabs {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  /* white-space: nowrap; */
}

.tabs-container .nav-item {
  flex: 1;
  text-align: center;
}

.tabs-container .tab-content {
  margin-top: 20px;
}
.cprabhu-tabs-description {
  width: auto;
  height: auto;
}

@media (max-width: 1000px) {
  .tabs-container .nav-tabs {
    width: auto;
    overflow-x: scroll;
  }

  .tabs-container .nav-item {
    flex: 1;
    min-width: 120px;
    text-align: center;
  }

  .tabs-container .tab-content {
    padding: 0 10px;
  }

  .model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    display: none;
  }
  .cprabhu-main-rooms-container {
    height: auto;
    width: auto;
  }
  .cprabhu-main-containers {
    display: inline-block;
  }
}
#search-card-grid-cprabhu {
  border-radius: 50px;
  width: fit-content;
  height: 90px;
  left: 5%;
  position: absolute;
  bottom: 22%;
}
