.image-container {
  width: 100%;
  position: relative;
  height: auto;
  display: inline-block;
}

.image-container img {
  width: 95%;
  height: 500px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}

.overlay-text {
  position: absolute;
  left: 50px;
  top: 150px;
  width: 400px;
  color: rgb(243, 244, 246);
  text-align: center;
  background-attachment: fixed;
}

.overlay-font {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 40px;
  font-style: normal;
  letter-spacing: -0.0847;
  text-wrap: wrap;
}

#search-card-grid-header {
  border-radius: 10px;
  width: auto;
  height: auto;
  /* left: 5%; */
  /* margin-left: 5px; */
  position: absolute;
  bottom: 30%;

  padding: 12px;
}

.responsive-wrapper {
  /* padding: 10px; */
}

.responsive-card-header {
  margin: 20px 0;
}

@media (max-width: 550px) {
  .responsive-wrapper {
    height: auto;
    padding-top: 70%;
    text-align: center;
    right: 1%;
  }

  .responsive-card-header {
    margin: 10px 0;
    display: flex;
  }

  .responsive-card-header .row {
    flex-direction: row;
  }

  .responsive-card-header .col {
    margin-bottom: 10px;
  }
  .headerContents {
    .content {
      margin-top: 20px;
      padding: 20px;
    }

    .contentimg {
      width: 100%;
      height: auto;
    }
  }

  .image-container {
    width: 100%;
    height: auto;
    right: 10%;
    width: fit-content;
  }
  .image-container img {
    /* width: 84%; */
    height: 200px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }

  .overlay-text {
    left: 5%;
    top: 20%;
    width: 70%;
  }

  .overlay-font {
    font-family: "Open Sans", sans-serif;
    color: white;
    width: 100%;
    font-weight: 500;
    font-size: 25px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* For medium screens */
  .responsive-wrapper {
    padding: 8px;
  }

  .responsive-card-header {
    margin: 15px 0;
    /* padding: 54px; */
  }

  .responsive-card-header .col {
    margin-bottom: 8px;
  }
  #search-card-grid-header {
    /* padding: 54px; */
    /* margin-bottom: 385px; */
  }
}

@media (min-width: 992px) {
  /* For large screens */
  .responsive-wrapper {
    padding: 10px;
  }

  .responsive-card-header {
    margin: 20px 0;
  }

  .responsive-card-header .row {
    flex-direction: column;
  }

  .responsive-card-header .col {
    margin-bottom: 0;
  }
  #search-card-grid-header {
    /* padding: 54px; */
    /* margin-bottom: 315px; */
    margin-left: 110px;
    /* display: flex; */
  }
}
@media (max-width: 820px) {
  #search-card-grid-header {
    bottom: auto;
    width: 276px;
    margin-top: -25rex;
    margin-left: 18px;
  }
}
