

   .navbar {
    height: 15vh;
    color: rgb(10, 9, 9);
    display: flex;
    align-items: center;
    justify-content: space-between; /* Changed to space-between for better distribution */
    padding: 0 20px; /* Added padding for better spacing */
    font-family: "Open Sans", sans-serif;
    top: 0;
    
}

.navbar-logo  img{
    margin-left: 50px;
width: 90px;
height: 90px;
}

.navbar-icons {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.navbar-menu-buttons {
    color: rgb(9, 9, 9);
    padding: 10px 20px;
    font-size: 18px;
    background: none;
    border: none;
    cursor: pointer;
}

.navbar-dropdown-menu {
    position: absolute;
    right: 0; /* Changed to right for better alignment */
    margin-top: 13px;
    justify-content: center;
    align-items: center;
    background-color: white;
    min-width: 200px;
    border-radius: 20px;
    box-shadow: 2px 1px 0px 0px black;
    border: 1px solid black;
    z-index: 1;
    padding: 12px 16px;
}

.A {
    color: black;
    padding: 10px 15px;
    text-decoration: none;
    display: block;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
}

.navbar-dropdown-menu > Link:hover {
    background-color: #f1f1f1;
}

@media only screen and (max-width: 924px) {
    .navbar {
        flex-direction: row ; /* Changed to column for vertical stacking */
        height: auto;
        justify-content: space-evenly;
        text-align: start; /* Changed to center for better alignment */
    }

    .navbar-logo {
        width: auto;
        text-align: start;
        font-size: 16px; /* Adjusted font size for smaller screens */
    }

    .navbar-icons {
        width: fit-content;
        font-size: small;
        margin: 10px 0; /* Added margin for better spacing */
    }

    .navbar-main-containers {
        margin-left: 0; /* Reset margin for mobile */
        width: 100%; /* Full width on mobile */
        text-align: center; /* Center align for better appearance */
    }

    .navbar-menu-buttons {
        font-size: 14px; /* Adjusted font size for smaller screens */
    }

    .navbar-dropdown-menu {
        height: auto;
        width: 100px;
        left: 90%; 
        transform: translateX(-50%); 
    }

    .A {

        font-size: 10px; /* Adjusted font size for better readability */
    }
}
