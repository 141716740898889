.about-heading {
  width: 100%;
  height: 70vh;
  position: relative;
}

video {
  height: 500px;
  width: 90%;
  object-fit: cover;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-left: 60px;
  border-radius: 20px;
  -webkit-filter: blur(0.5px);
  filter: blur(0.5px);
}

.about-text {
  position: absolute;
  top: 30%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
  width: 80%;
  padding: 20px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 900;
  font-size: 50px;
}

.about-para {
  position: absolute;
  top: 50%;
  left: 50%;
  color: rgb(240, 235, 235);
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 25px;
}

.main-About {
  display: flex;
  margin-top: 35px;
  padding-left: 150px;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
}

.question-main {
  height: 300px;
  padding-left: 150px;
  font-family: "Open Sans", sans-serif;
}

.question-container {
  width: auto;
  height: 200px;
  display: flex;
  gap: 30px;
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 200;
}

.dropdown-main {
  display: flex;
}

.gallery-container {
  width: 50%;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  margin-left: 400px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  padding: 16px;
}

.gallery-item {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.gallery-item:hover {
  transform: translateY(-10px);
}

.gallery-item-0 {
  transform: translateY(20px);
}

.gallery-item-1 {
  transform: translateY(-20px);
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
}

.about-cards {
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

.cards-container {
  display: flex;
  width: auto;
  height: auto;
  gap: 30px;
  text-align: center;
  padding-left: 150px;
  font-family: "Open Sans", sans-serif;
}

.cards-cnt {
  display: flex;
  width: auto;
  height: auto;
  gap: 30px;
  text-align: center;
  padding-left: 150px;
  font-family: "Open Sans", sans-serif;
}

.single-card {
  margin-top: 10px;
  width: 400px;
}
.card-description {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
.card-content {
  height: 150px;
  padding: 10px;
}

.sub-container {
  width: auto;
  height: auto;
}

.cards-cnt h2 {
  font-size: 22px;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}

.cards-cnt p {
  font-size: 18px;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}

@media only screen and (max-width: 900px) {
  .question-container {
    width: auto;
    display: inline-block;
    /* top: 10%; */
    margin-right: 100px;
  }

  .about-text {
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
  }

  .about-para {
    color: rgb(247, 243, 243);
    top: 70%;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
  }

  .main-About {
    font-size: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .cards-container {
    width: 100%;
    display: inline-block;
    padding: 0 20px;
  }

  .cards-cnt {
    width: 100%;
    display: inline-block;
    padding-left: 10px;
    margin-top: 150px;
  }

  .sub-container {
    width: auto;
    padding-right: 50px;
  }

  .gallery-container {
    width: auto;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 20px;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    gap: 16px;
  }
  .gallery-item {
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(0);
    transition: transform 0.3s ease;
  }

  .gallery-item:hover {
    transform: translateY(-10px);
  }

  .gallery-item-0 {
    transform: translateY(20px);
  }

  .gallery-item-1 {
    transform: translateY(-20px);
  }

  .gallery-item img {
    width: 100%;
    height: auto;
    display: block;
  }
  .question-main {
    font-size: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 600px) {
  video {
    width: 100%;
    margin-left: 0;
    border-radius: 0;
  }

  .about-text {
    font-size: 24px;
    padding: 10px;
  }

  .about-para {
    font-size: 16px;
    padding: 10px;
  }

  .main-About {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .question-container {
    width: auto;
    padding: 0 10px;
    display: inline-block;
  }

  .cards-container,
  .cards-cnt {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
  }

  .single-card {
    width: 100%;
  }

  .sub-container {
    width: auto;
    height: auto;
  }

  .gallery-container {
    width: 100%;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    padding: 10px;
  }
  .question-main {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
  }
}
