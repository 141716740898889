.career-main {
    width: 88%;
    height: 80vh;
    margin-left: 50px;
    display: flex;
    border-radius: 10px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: linear-gradient(to left, pink, skyblue);
    padding: 20px; 
    box-sizing: border-box; 
  }
  
  .career-buttons {
    margin-top: 30px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    font-family: 'Open Sans', sans-serif;
  }
  
  .career-btn {
    width: 100%;
    height: auto;
    text-align: center;
  }
  
  @media (max-width: 600px) {
    .career-main {
        margin-left: 20px;
      flex-direction: column; /* Stack items vertically on smaller screens */
      height: auto; /* Allow height to adjust based on content */
      padding: 10px; /* Reduce padding for smaller screens */
    }
  
    .career-buttons {
      flex-direction: column; /* Stack buttons vertically */
      align-items: center; /* Center align buttons */
      gap: 20px; /* Add space between buttons */
    }
  
    .career-btn {
      width: 80%; /* Adjust width for better touch interaction */
      margin-bottom: 20px; /* Add margin between buttons */
    }
  }
  