.ooty-section{
    margin-bottom: 100px;
    
}
.ooty-main{
    height: auto;
    width: 100%;
    text-align: center;
    justify-content: center;
    display: inline-block;
    position: relative;
}
.ooty-main img{
    width: 95%;
    height: 500px;
    align-items: center;
    border-radius: 25px;
    justify-content: center;
}
.ooty-overlay-text{
    position: absolute;
    left: 35%;
    top: 45%;
    width: 400px;
    color: rgb(243, 244, 246);
    text-align: center;
    background-attachment: fixed;
}
.ooty-overlay-font{
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 40px;
    font-style: normal;
    letter-spacing: -.0847;
     text-wrap: wrap;
}
.ooty-contnt{
    margin-top: 90px;
}
/* .ooty-contnt p{
    font-size: 16px;
    font-family: 'Roboto',sans-serif;
    margin-top: 10px;
    width: 100%;
    max-width: 1150px;
    font-weight: 400;
} */
 .ooty-main-paragraph{
    width: auto;
    height: auto;
    align-items: center;
    justify-content: center;
    text-align: start;
    font-family: 'Roboto',sans-serif;

 }
.ooty-desctext {
    width: fit-content;
    height: 100px;
    white-space: normal; 
    font-family: 'Open Sans',sans-serif;
  }


.ooty-room-cards-container{
    width: 15rem;
    border-radius: 20px;
    /* color: red; */
}

.card-div{
    padding: 20px;
    display: flex;
    width: auto;
    height: auto;
    /* color: red; */
}

.ooty-containers{
    display: flex;
    gap: 10px;
    align-items: center;
}
.ooty-room-details{
    width: auto;
    height: auto;

    font-family: 'Roboto',sans-serif;
}
.ootytabs-content{
    margin-top: 35px;
    align-items: center;
    justify-content: center;

}
#search-card-grid {
    border-radius: 50px;
    width: fit-content;
    height: auto;
    left: 13%;
    position: absolute;
    bottom: 22%;
}
.ooty-cards{
    height: 180px;
    width: 250px;
    display: flex;
    gap: 20px;

}
@media only screen and (max-width: 800px) {

    .ooty-main{
        height: auto;
        width: 400px;
    }
    .ooty-main img{
        height: 300px;
        width: 400px;
    }
    
    /* .ooty-contnt p{
        height: auto;
        width: 400px;
    } */
    .cardlist{
        height: auto;
        width: fit-content;
        max-width: 300px;
    }
    .card-div{
        height: auto;
        display: inline-block;
    }
    
    .ooty-containers{
        display: inline-block;
        text-align: center;

    }
    .ooty-room-details {
        width: fit-content;
        height: auto;
        text-align: start;
        
    }
    .ooty-cards{
        width: 30rem;
        border-radius: 20px;
    }
    
}
.explore-cards-container {
    width: auto;
    margin: 0 auto;
  }
 
  .explore-cards {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-bottom: 20px;
  }
 
  .card-wrapper {
    flex: 0 0 auto;
    padding: 10px;
    width: calc(100% - 20px); /* Adjust card width with padding */
  }
 
  @media (max-width: 768px) {
    .explore-cards {
      overflow-x: hidden;
      justify-content: center;
    }
 
    .card-wrapper {
        /* margin-left: 100px; */

      flex: 0 0 auto;
      padding: 10px;
      width: calc(25% - 20px); /* Show four cards per row */
    }
    .ooty-overlay-text{
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
    }
    .ooty-overlay-font{
        font-size: 30px;

    }
    

   
  }
  @media (max-width: 480px) {
    .ooty-overlay-font {
        font-size: 20px;
        /* display: none; */
    }
}
 