.chennai-section{
    /* margin-left: 100px; */

}
.chennai-main{
   
    height: auto;
    width: 100%;
    text-align: center;
    justify-content: center;
    display: inline-block;
    position: relative;
}
.chennai-main img{
    width: 95%;
    height: 500px;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    /* margin-right: 30px; */
}
.chennai-overlay-text{
    position: absolute;
    left: 35%;
    top: 45%;
    width: 400px;
    color: rgb(243, 244, 246);
    text-align: center;
    background-attachment: fixed;
}
.chennai-overlay-font{
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 40px;
    font-style: normal;
    letter-spacing: -.0847;
     text-wrap: wrap;
}
.chennai-contnt{
    margin-top: 90px;
    font-family: "Open Sans", sans-serif;

}
.chennai-section{
  margin-bottom: 100px;
}

.chennaitabs-content{
    margin-top: 35px;
    justify-content: center;
}

.facilities-chennai{
    
   
    max-height: 90px;
    max-width: 90px;
}
.facilities{
    width: 600px;
    height: auto;
}
.chennai-main-paragraph{
  width: auto;
   height: auto;
   align-items: center;
   justify-content: center;
   text-align: start;
   font-family: 'Roboto',sans-serif;
}

  .chennai-desctext{
    font-family: 'Open Sans',sans-serif;
    height: 100px;
  }

  .card-div {
    display: flex;
    /* flex-direction: column; */
  }

  .explore-cards-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .explore-cards {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }

  .card-wrapper {
    flex: 0 0 auto;
    padding: 10px;
    width: 100%;
    scroll-snap-align: center;
  }

  .chennai-room-cards {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .tabs-weather{
    width: 500px;
    height: 300px;
  }
  .tabs-location{
    width: 500px;
    height: 300px;
  }

  #search-card-grid {
    border-radius: 50px;
    width: fit-content;
    height: auto;
    left: 13%;
    position: absolute;
    bottom: 22%;
}

  @media (max-width: 800px) {
    .card-div {
      height: auto;
      display: inline-block;
    }

   .chennai-room-cards{
    height: auto;
    width: auto;
    display: inline-block;
   }
    .cards {
      flex-direction: row;
    }

    .explore-cards {
      justify-content: center;
    }

    .card-wrapper {
      width: calc(25% - 20px);
    }
    .tabs-weather img{
        width: 100px;
    }
    .tabs-weather{
        width: 500px;
        height: 200px;
      }
      .tabs-location{
        width: 500px;
        height: 200px;
      }
      
    .chennai-overlay-text {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
    }

    .chennai-overlay-font {
        font-size: 30px;
    }

  }
  @media (max-width: 480px) {
    .bengaluru-overlay-font {
        font-size: 20px;
        color: rebeccapurple;
    }
}
  