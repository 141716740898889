.mainrateing{
    display: flex;
   margin-left: 90px;   
   /* width:100%; */
   /* margin: 80px; */
   margin-top: 100px;
   /* width: 100%; */
   padding: 20px;
   text-align: center;
   /* justify-content:space-between; */
   height: 250px;
   /* width: 100% */
   
   /* background-color: rgba(142,  208, 0.167); */
}

.submenu{
    /* display: flex; */
    /* margin-right: 30px; */
    /* /* margin-left: 50px; */
   /* margin-top: 50px;  */
   /* gap: "50px"; */
   width: 310px;
}
.subtext{
    /* gap: 20px; */
  margin-left: 50px;
  display: flex;
} 
.bookingimg{
   
    width: 230px;
   
    height:50px;
   
}
@media only screen and (min-device-width: 769px) and (max-device-width: 1024px) {
 
 
    .mainrateing{
       width: 100%;
       grid-area: 50px;
  }
   
  .submenu{
width: 100%;  }
   
  }
  @media only screen and (min-device-width: 250px) and (max-device-width: 769px) {
 
 
    .mainrateing{
       /* width: 100%; */
       display: inline;
       gap: 20px;
       margin-left: 100px;
height: 50px;
  }
   
  .submenu{
width: 100%;  
margin-left: 20px;
}
   
  }