.main-footer-content {
  background-color: rgb(13, 13, 13);
  width: auto;
}

.footer-section {
  margin-left: 100px;
}

.footer-content {
  display: flex;
  height: auto;
  width: 100%;
  text-align: start;
  color: whitesmoke;
  margin-top: 100px;
  justify-content: space-evenly;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  font-weight: 200;
}
.content-footer {
  color: gray;
  font-family: "Open Sans", sans-serif;
  width: 400px;
  text-align: start;
  font-size: 20px;
}
.head-footer {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 200;
  margin-top: 20px;
}

.social-icons {
  color: whitesmoke;
  width: auto;
  justify-content: space-evenly;
}

.social-icons ul {
  width: auto;
}

.footer-list a {
  gap: 30px;
  font-weight: 400;
  font-size: 18px;
  color: grey;
  text-decoration: none;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  height: auto;
  display: block;
  text-align: start;
}

a:after {
  display: block;
  border-bottom: solid 3px #b7bebf;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

a:hover:after {
  transform: scaleX(1);
}

@media only screen and (max-width: 900px) {
  .main-footer-content {
    width: 100%;
  }

  .footer-content {
    display: block;
    width: auto;
    text-align: start;
    justify-content: center;
    margin: 20px auto;
    font-size: 14px;
    height: auto;
    padding: 20px;
  }

  .footer-section {
    margin: 0 auto;
    padding: 10px;
  }

  .content-footer {
    height: auto;
    width: auto;
  }

  .head {
    width: 100%;
    text-align: start;
  }

  .footer-list {
    display: inline-block;
    width: 100%;
  }

  .footer-list a {
    display: flex;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .social-icons {
    display: flex;
    width: auto;
  }

  .social-icons ul {
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style: none;
  }
}
