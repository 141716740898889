
.privacy-main-container {
  display: flex;
  /* flex-direction: column;  */
  margin: 50px auto;
  font-family: 'Open Sans',sans-serif;
}

.privacy-list-container {
  border: none;
  font-family: 'Open Sans',sans-serif;

  width: 100%;
  max-width: 500px; /* Limit width for large screens */
  box-shadow: none;
  position: sticky;
  top: 0;
  padding: 10px; /* Add padding for better spacing */
}

.privacy-sections-container {
  font-family: 'Open Sans',sans-serif;

  margin: 0 auto;
  overflow-y: auto;
  height: 80vh;
  width: fit-content;
}

.privacy-sections {
  font-family: 'Open Sans',sans-serif;

  width: 100%;
  max-width: 400px; /* Ensure fixed width for sections */
  margin-bottom: 20px;
  color: rgb(58, 57, 57);
  position: relative; /* Changed from fixed to relative */
}

.privacy-list-group-item {
  font-family: 'Open Sans',sans-serif;

  list-style-type: none;
  padding: 10px;
  cursor: pointer;
  border-left: 5px solid transparent; /* Transparent border */
}

.privacy-list-group-item.active {
  width: fit-content;
  background-color: rgb(233, 226, 226);
  color: red;
 
}

.privacy-list-group-item a {
  /* color:orange; */
  text-decoration: none;
}

.privacy-list-group-item a:hover {
  transform: scale(1.1);
  transition: 0.3s; /* Faster transition for hover effect */
  color: rgb(212, 125, 10);
}

@media only screen and (max-width: 900px) {
  .privacy-main-container {
    margin: 20px;
  }

  .privacy-list-container {

    width: 90%; /* Increase width on medium screens */
    margin: 0 auto; /* Center align on medium screens */
  }

  .privacy-sections-container {
    width: 90%; /* Adjust width for medium screens */
  }
}

@media only screen and (max-width: 600px) {
  .privacy-main-container {
    
    margin: 10px;
    flex-direction: column; /* Stack items vertically on small screens */
  }

  .privacy-list-container {
    display: none;
    width: 100%;
    margin: 0 auto;
    padding: 5px; /* Reduce padding for smaller screens */
  }

  .privacy-sections-container {
    width: 100%;
    margin-top: 20px;
  }

  .privacy-sections {
    width: 100%; /* Ensure full width for small screens */
    position: relative; /* Use relative positioning */
  }
}
