.roomcardlist {
  width: 950px;
  height: 300px;
  border: 2px solid whitesmoke;
  box-shadow: 2px 2px 1px 1px black;
  box-sizing: border-box;
}
.min-ottycards {
  display: flex;
  gap: 50px;
  margin-bottom: 20px;
}
.oprabhu-main {
  height: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: inline-block;
  position: relative;
}
.oprabhu-main img {
  width: 92%;
  height: 500px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}
.ot-groupicons {
  margin-top: 10px;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: white;
}
::-webkit-scrollbar-thumb {
  background-color: whitesmoke;
}
::-webkit-scrollbar-thumb:hover {
  background-color: whitesmoke;
}
.opcontnt {
  margin-top: 50px;
}

.oprabhu-main-container {
  display: flex;
  height: auto;
  gap: 20px;
  margin-top: 30px;
}
.oprabhu-tabs-description {
  height: auto;
  width: auto;
}
.oprabhu-main-rooms-container {
  height: auto;
  width: 157vh;
}
.ooty-prabhu-myselection {
  position: sticky;
  top: 0;
  width: 350px;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
}
.counter-cnt button {
  border: none;
  background-color: whitesmoke;
}
.counter-value {
  font-size: 18px;
  margin: 0 10px; /* Adjusted margin for better spacing */
}

.ooty-btn-container {
  display: flex;
  align-items: center;
  font-size: large;
  justify-content: space-evenly;
}

.counter-cnt .btn-container {
  display: flex;
  align-items: center;
}

.ooty-prabhu-myselection {
  width: auto;
  text-align: start;
  gap: 10px;
}
.facilities-ooty {
  gap: 40px;
  max-height: 40px;
  max-width: 40px;
}
.amenites {
  width: 200px;
  height: auto;
}
.facilities-ooty-text {
  width: 130px;
}
#search-card-grid-oprabhu {
  border-radius: 50px;
  width: fit-content;
  height: auto;
  left: 5%;
  position: absolute;
  bottom: 22%;
}
.ooty-add-btn,
.increment-btn,
.decrement-btn {
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin: 0 5px;
}
.ooty-add-btn {
  border-radius: 20px;
  font-family: "Open Sans";
  font-size: 16px;
  width: 100px;
  box-shadow: none;
  border: 1px solid black;
}
.ooty-add-btn:hover {
  background-color: #0e0e0f;
  color: white;
}

.increment-btn {
  color: black;
  font-size: 20px;
  background-color: white;
  border-radius: 70%;
}
.increment-btn:hover {
  width: fit-content;
  height: fit-content;
  background-color: black;
  color: white;
  box-shadow: 1px 1px 0px 0px rgb(193, 194, 198);
}

.decrement-btn {
  background-color: white;
  font-size: 20px;
  color: black;
  border-radius: 70%;
}
.decrement-btn:hover {
  width: fit-content;
  height: fit-content;
  background-color: black;
  color: white;
  box-shadow: 1px 1px 0px 0px gray;
}
.increment-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  color: gray;
}
.count-dis {
  font-size: 22px;
  margin: 0 10px;
  min-width: 30px;
  text-align: center;
}
.tabs-container {
  margin: 20px;
}

.tabs-container .nav-tabs {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
}

.tabs-container .nav-item {
  flex: 1;
  text-align: center;
}

.tabs-container .tab-content {
  margin-top: 20px;
}
.oprabhu-tabs-description {
  width: auto;
  height: auto;
}

@media (max-width: 1000px) {
  .tabs-container .nav-tabs {
    width: auto;
    overflow-x: scroll;
  }

  .tabs-container .nav-item {
    flex: 1;
    min-width: 120px;
    text-align: center;
  }

  .tabs-container .tab-content {
    padding: 0 10px;
  }

  .model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    display: none;
  }
  .oprabhu-main-container {
    display: inline-block;
  }
  .oprabhu-main-rooms-container {
    height: auto;
    width: auto;
  }
  .oprabhu-tabs-description {
    width: auto;
    height: auto;
  }
  .oprabhu-main-container {
    display: inline-block;
  }
}
@media (max-width: 480px) {
  .oprabhu-main img {
    width: 84%;
    height: 200px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }
}
