


 .contact-containers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.contact-img {
  
  width: 100%; /* Ensure image is responsive */
  height: auto;
}

.overtext-contact {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
 color: whitesmoke;
}

.overfont-contact {
  font-style: normal;
  font-size: 50px;
  font-weight: 900;
  font-family: "Open Sans", sans-serif;
}



.group-contact {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  margin: 40px auto;
  width: 60%;
  max-width: 1100px;
  row-gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .content {
      width: auto;
      margin-right: 50px;
      align-items: center;
      justify-content: center;
  }
}

@media only screen and (max-width: 900px) {
  .content {
      width: auto;
      margin-right: 50px;
      align-items: center;
      justify-content: center;
  }
}

@media only screen and (max-width: 600px) {
  .contact-form {
      width: 80%; /* Adjust width for smaller screens */
  }

  .group-contact {
      display: none; /* Hide group contact on smaller screens */
  }

  .content {
      width: auto;
      margin: 0 10px; /* Add some margin for padding */
      align-items: center;
      justify-content: center;
      text-align: center; /* Center text for better readability */
  }
  .overtext-contact {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   left: 15%;
  }
  
  .overfont-contact {
    color:whitesmoke;
    font-style: normal;
    font-size: 20px;
    font-weight: 900;
    font-family: "Open Sans", sans-serif;
  }
}

