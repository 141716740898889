
.banglore-room-details{
    font-family: 'Open Sans',sans-serif;
    margin-left: 20px;
    
}


.bng-main {
    height: auto;
    width: 100%;
    text-align: center;
    justify-content: center;
    display: inline-block;
    position: relative;
}

.bng-main img {
    width: 95%;
    border-radius: 25px;
    height: 500px;
    align-items: center;
    justify-content: center;
}

.bengaluru-main-contnt {
    display: flex;
    gap: 10px;
    font-family: 'Open Sans', sans-serif;
}

.bengaluru-overlay-text {
    position: absolute;
    left: 35%;
    top: 45%;
    width: 400px;
    color: rgb(243, 244, 246);
    text-align: center;
    background-attachment: fixed;
}

.bengaluru-overlay-font {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 40px;
    font-style: normal;
    letter-spacing: -.0847;
    text-wrap: wrap;
}

.bengaluru-main-paragraph{
   width: auto;
   height: auto;
   align-items: center;
   justify-content: center;
   text-align: start;
   font-family: 'Roboto',sans-serif;
}
.bengaluru-cards-text {
   width: fit-content;
   height: auto;
  }
.bengaluru-maincontnt{
    margin-top: 90px;
    font-family: "Open Sans", sans-serif;
   
}
.image-wrapper {
    position: relative;
    display: inline-block;
}
.hover-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
}
.image-wrapper:hover .hover-text {
    opacity: 1;
}
 

.bengalurutabs-content{
    margin-top: 35px;
    justify-content: center;
}
.bengalurutabs-content p{
    font-family: "Roboto", sans-serif


}
.bengaluru-section{
    margin-bottom: 100px;
}
.bengalluru-cards{
    width: 15rem;
    border-radius: 20px;
}


.cardlist{
    width: 900px;
    height: 300px;

}
.card-div{
    display: flex;
    padding: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 5px 5px 5px 5px black;
}
.card-div:hover{
    box-shadow: 5px 5px 5px 5px black;
    transition: 1;
    transform: scale(1);
    background-color: rgb(214, 211, 211);
}
.cards{
    height: 180px;
    width: 250px;
    display: flex;
    gap: 20px;
   
   
}
.staricon{
    width: fit-content;
    width: 80px;
    height: 30px;
    align-items: center;
    justify-content: center;
    padding: 4px;
    margin-left: 20px;
}
.tabs-content{
    padding: 100px;
}
.bengaluru-main-roomcards{
    display: flex;
    gap: 10px;
    /* align-items: center; */
}
.bengaluru-cards-text{
    height: 100px;
    font-family: 'Open Sans',sans-serif;
}
#search-card-grid {
    border-radius: 50px;
    width: fit-content;
    height: auto;
    left: 13%;
    position: absolute;
    bottom: 22%;
}
@media only screen and (max-width: 800px) {
    
    .bng-main{
        height: auto;
        width: 400px;
    }
   
    .bng-main img{
       height: 300px;
       width: 400px;
    }
    
    
    .bengaluru-main-paragraph{
        height: auto;
        width: auto;
    }
    .cardlist{
        height: auto;
        width: fit-content;
    }
    .card-div{
        width: fit-content;
        height: auto;
        display: inline-block;
    }
    
    .banglore-room-details{
       width: 100%;

    }
    .bengalluru-cards{
        width: 30rem;
        border-radius: 20px;
    }
    
    .bengaluru-cards-text {
        font-family: 'Open Sans',sans-serif;
       }
       .bengaluru-main-roomcards{
        display: inline-block;
        gap: 10px;
    }
    
    .responsive-wrapper {
        position: relative;
        width: 100%;
        height: auto;
    }

    .responsive-card {
        width: 90%;
        bottom: auto;
        left: auto;
        background-color: white;
    }
    .tabs-weather img{
        width: 100px;
    }
    .tabs-weather{
        width: 500px;
        height: 200px;
      }
      .tabs-location{
        width: 500px;
        height: 200px;
      }
  }
  




  @media (max-width: 768px) {
    .explore-cards {
      overflow-x: hidden;
      justify-content: center;
    }
  
    .card-wrapper {
      flex: 0 0 auto;
      padding: 10px;
      width: calc(25% - 20px); 
    }
    .bengaluru-cards-text {
        width: fit-content;
        height: auto;
    
       }
       .bng-main img {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }

    .bengaluru-overlay-text {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
    }

    .bengaluru-overlay-font {
        font-size: 30px;
    }

  }
  @media (max-width: 480px) {
    .bengaluru-overlay-font {
        font-size: 20px;
    }
}
   