
   * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main-slider {
    height: 500px;
    margin-top: 100px;
    width: 100%;
    justify-content: space-between;
}

.slider {
    margin-top: 200px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.slides-div {
    height: 400px;
    width: 300px;
    margin: 20px;
    margin-left: 100px;
    font-weight: 10;
    position: relative;
    font-family: "Open Sans", sans-serif;
}

.slides-div:hover {
    -webkit-transform: scale(1.1, 1.1);
    transform: scale(1.1, 1.1);
    filter: brightness(0.5);
    transition-duration: 1.0s;
    background: none;
    border: none;
}

.slides-div img {
    height: 400px;
    width: 100%;
    opacity: 0.9;
    position: absolute;
    object-fit: cover;
    border-radius: 20px;
}

.slides-div h1 {
    position: absolute;
    top: 50px;
    left: 20px;
    margin: 0;
    height: min-content;
    font-family: "Open Sans", sans-serif;
    font-weight: 100;
    font-style: normal;
    color: white;
}

.slides-div p {
    position: absolute;
    bottom: 20px;
    left: 20px;
    margin: 0;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    font-style: normal;
    font-size: 25px;
    padding: 10px;
}

@media (max-width: 768px) {
    .main-slider {
        margin-top: 50px;
    }

    .slider {
        margin-top: 150px;
    }

    .slides-div {
        width: 70%;
        margin-left: 0;
        margin: 10px;
    }
}

@media (max-width: 600px) {
    .slides-div {
        width: 80%;
        margin: 10px auto;
    }

    .slider {
        margin-top: 100px;
    }

    .main-slider {
        margin-top: 50px;
    }

    .slides-div p {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .slides-div {
        width: 100%;
        margin: 10px auto;
    }

    .main-slider {
        margin-top: 20px;
    }

    .slider {
        margin-top: 50px;
    }

    .slides-div p {
        font-size: 16px;
    }
}

